import _ from "lodash"
import {
  useState,
} from "react"

function useMutate({
  url,
  authToken = null,
  payload = null,
  signedContext = null,
  method = "POST",
  errorPaths = ["error", "errorMessage", "error.message"],
  fetchOptions = {},
  onFinally = () => {},
  onSuccess = () => {},
  onFailure = () => {},
}) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  const hasAuth = authToken || (payload && signedContext)
  const mutate = (body) => {
    if (!hasAuth || !url) {
      console.log({
        payload,
        signedContext,
        authToken,
        url,
      })
      throw new Error("payload, signedContext, authToken & url are required for useMutate hook")
    }
    if (!body) {
      setError("Body is required.")
      return
    }

    setLoading(true)
    const headers = {
      "Content-Type": "application/json",
    }
    if (authToken) {
      headers.Authorization = `Bearer ${authToken}`
    }

    if (payload && signedContext) {
      headers["X-Woztell-Payload"] = JSON.stringify(payload)
      headers["X-Woztell-SignedContext"] = signedContext
    }

    fetch(url, {
      method,
      headers,
      body: JSON.stringify(body),
      ...fetchOptions,
    })
      .then((res) => res.json())
      .then((result) => {
        const somePathHasError = errorPaths?.some((path) => _.get(result, path) && typeof _.get(result, path) === "string")
        if (somePathHasError) {
          // eslint-disable-next-line no-restricted-syntax
          for (const path of errorPaths) {
            const apiError = _.get(result, path)
            if (apiError && typeof apiError === "string") {
              setError(apiError)
              onFailure(apiError)
              console.error(apiError)
              return
            }
          }
          setError("Something went wrong.")
          onFailure("Something went wrong.")
        } else {
          setError("")
          onSuccess(result)
        }
      })
      .catch((e) => {
        console.error(e)
        setError(e?.message || "Something went wrong")
        onFailure(e?.message || "Something went wrong")
      })
      .finally(() => {
        setLoading(false)
        onFinally()
      })
  }

  return {
    error,
    loading,
    mutate,
  }
}

export {
  useMutate,
}
