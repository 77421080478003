import styled from "@emotion/styled"

export const ModalContainer = styled.div`
  width: ${(props) => props.width ?? "700px"};
  min-width: 520px;
  height: ${(props) => props.height ?? "400px"};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: ${(props) => props.theme.modalBoxShadow};
  border-radius: ${(props) => props.theme.modalBorderRadius};
  overflow: hidden;
  background-color: white;
  margin: 16px;
`

export const BodyContainer = styled.div`
  overflow-y: auto;
  padding: 16px;
  flex: 1;
`

export const Header = styled.div`
  background-color: ${(props) => props.theme.themeColorDarkV2};
  padding: 32px;
  color: white;
  font-size: ${(props) => props.theme.textMd};
  font-weight: 500;
`

export const ActionBar = styled.div`
  box-sizing: border-box;
  border-top: 1px solid ${(props) => props.theme.gray1};
  padding: 8px 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  justify-self: flex-end;

  .box-button-container {
    margin-left: 8px;
  }
`
