import React from "react"
import * as Styles from "./ThemedModalStyles"
import NewBoxButton from "../NewBoxButton/NewBoxButton"
import Modal from "../Modal/Modal"

function ModalContainer({
  isModalOpen = true,
  width,
  height,
  children,
  style,
}) {
  return (
    <Modal open={isModalOpen}>
      {() => (
        <Styles.ModalContainer style={style} width={width} height={height}>
          {children}
        </Styles.ModalContainer>
      )}
    </Modal>
  )
}

function ModalBody({ children, style }) {
  return <Styles.BodyContainer style={style}>{children}</Styles.BodyContainer>
}

function ModalHeader({ children, style }) {
  return <Styles.Header style={style}>{children}</Styles.Header>
}

function ModalFooter({
  cancelButtonText = "Cancel",
  submitButtonText = "Submit",
  submitButtonDisabled = false,
  loading = false,
  onSubmit = () => {},
  onCancel = () => {},
  showCancelButton = true,
  style,
}) {
  return (
    <Styles.ActionBar style={style}>
      {showCancelButton && (
        <NewBoxButton
          text={cancelButtonText}
          icon="times"
          onClick={() => {
            onCancel?.()
          }}
        />
      )}
      <NewBoxButton
        primary
        loading={loading}
        disabled={submitButtonDisabled}
        text={submitButtonText}
        icon="plus"
        onClick={() => {
          onSubmit?.()
        }}
      />
    </Styles.ActionBar>
  )
}

export {
  ModalContainer,
  ModalHeader,
  ModalBody,
  ModalFooter,
}
