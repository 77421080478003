/* eslint-disable no-undef */

import React, { Component } from "react"
import { BrowserRouter, Route } from "react-router-dom"
import { ThemeProvider } from "theme-ui"
import loadable from "loadable-components"
import "./App.scss"
import CreateGroup from "./pages/CreateGroup/CreateGroup"
import UpdateGroup from "./pages/UpdateGroup/UpdateGroup"

const ROUTER_BASE = process.env.REACT_APP_ROUTER_BASE || "/"

const textScalerRatio = 1.2

const theme = {
  colors: {
    text: "#2e2e2e",
    background: "#fff",
    primary: "hsl(215, 100%, 47%)",
    white: "#fff",
    black: "#2e2e2e",
    secondary: "#c0c",
    success: "hsl(94, 48%, 56%)",
    warning: "hsl(46, 100%, 61%)",
    error: "hsl(8, 74%, 49%)",
    dangerText: "#cc0000",
    gray12: "#1d1d1d",
    gray11: "#272727",
    gray10: "#2e2e2e",
    gray9: "#3c3c3c",
    gray7: "#626262",
    gray6: "#707070",
    gray5: "#929292",
    gray4: "#a5a5a6",
    gray3: "#bbbbbc",
    gray2: "#cccccc",
    gray15: "#e6e6e6",
    gray1: "#e8e7e8",
    gray05: "#f0f0f0",
    gray02: "#f7f7f7",
    gray0: "#fafafa",
  },
  text: "#2e2e2e",
  background: "#fff",
  primary: "hsl(215, 100%, 47%)",
  white: "#fff",
  black: "#2e2e2e",
  secondary: "#c0c",
  success: "hsl(94, 48%, 56%)",
  warning: "hsl(46, 100%, 61%)",
  error: "hsl(8, 74%, 49%)",
  dangerText: "#cc0000",
  facebookThemeColor: "linear-gradient(45deg, #0668E1, #0080FB )",
  themeColorDark: "#173753",
  themeColorDarkV2: "#3046ac",
  themeText: "#3366ca",
  themeColor: "#0063f0",
  palerBackground: "#f8f8f8",
  lightButtonIcon: "#b9b9b9",
  lightButtonBg: "#f8f8f8",
  highlight: "#e0e",
  muted: "#f6f6ff",
  paleBackground: "#f0f2f7",
  superPalerBackground: "#f3f3f3",
  themeColorLight: "#1D70A2",
  themeColorLightFocus: "#ecf3ff",
  selectorFocus: "#9d9d9d",
  borderColorDark: "#dbdbdb",
  borderColor: "#ededed",
  borderColorLight: "#dcdcdc",
  modalBoxShadow: "0 7px 8px -4px rgba(0,0,0,0.2), 0 13px 19px 2px rgba(0,0,0,0.14), 0 5px 24px 4px rgba(0,0,0,0.12)",
  modalBorderRadius: "4px",
  themeColorHighlight: "#66acff",
  placeholderText: "#bbbbbc",
  tooltipLightBackground: "#555",
  gray12: "#1d1d1d",
  gray11: "#272727",
  gray10: "#2e2e2e",
  gray9: "#3c3c3c",
  gray7: "#626262",
  gray6: "#707070",
  gray5: "#929292",
  gray4: "#a5a5a6",
  gray3: "#bbbbbc",
  gray2: "#cccccc",
  gray15: "#e6e6e6",
  gray1: "#e8e7e8",
  gray05: "#f0f0f0",
  gray02: "#f7f7f7",
  gray0: "#fafafa",
  selectorBorder: "hsl(0,0%,80%)",
  textXxxxs: `${1 / (textScalerRatio * textScalerRatio * textScalerRatio * textScalerRatio * textScalerRatio)}em`,
  textXxxs: `${1 / (textScalerRatio * textScalerRatio * textScalerRatio * textScalerRatio)}em`,
  textXxs: `${1 / (textScalerRatio * textScalerRatio * textScalerRatio)}em`,
  textXs: `${1 / (textScalerRatio * textScalerRatio)}em`,
  textSm: `${1 / textScalerRatio}em`,
  textMd: `${1 * textScalerRatio}em`,
  textLg: `${1 * (textScalerRatio * textScalerRatio)}em`,
  textXl: `${1 * (textScalerRatio * textScalerRatio * textScalerRatio)}em`,
  textXxl: `${1 * (textScalerRatio * textScalerRatio * textScalerRatio * textScalerRatio)}em`,
  textXxxl: `${1 * (textScalerRatio * textScalerRatio * textScalerRatio * textScalerRatio * textScalerRatio)}em`,
  textXxxxl: `${1 * (textScalerRatio * textScalerRatio * textScalerRatio * textScalerRatio * textScalerRatio * textScalerRatio)}em`,
  themeColorSolid: "#1B4353",
}

const Home = loadable(() => import("./pages/Home/Home"))
const Analytics = loadable(() => import("./pages/Analytics/Analytics"))
const Media = loadable(() => import("./pages/Media/Media"))
const InboxMessageTemplate = loadable(() => import("./pages/InboxMessageTemplate/InboxMessageTemplate"))
const InboxProdutsExtension = loadable(() => import("./pages/InboxProductsExtension/InboxProductsExtension"))
const InboxProdutsMessageExtension = loadable(() => import("./pages/InboxProductsMessageExtension"))
const FacebookAuth = loadable(() => import("./pages/FacebookAuth/FacebookAuth"))
const PushMessageSettings = loadable(() => import("./pages/PushMessageSettings/PushMessageSettings"))
const MetaAdsTemplate = loadable(() => import("./pages/MetaAdsTemplate/MetaAdsTemplate"))
const MetaAdsPermission = loadable(() => import("./pages/MetaAdsPermission/MetaAdsPermisson"))

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter basename={ROUTER_BASE}>
          <div className="app">
            <Route
              path="/remote"
              exact
              render={(props) => (
                <Home
                  {...props}
                />
              )}
            />
            <Route
              path="/"
              exact
              render={(props) => (
                <Home
                  {...props}
                />
              )}
            />
            <Route
              path="/analytics"
              exact
              render={(props) => (
                <Analytics
                  {...props}
                />
              )}
            />
            <Route
              path="/media"
              exact
              render={(props) => (
                <Media
                  {...props}
                />
              )}
            />
            <Route
              path="/inbox-message-template"
              exact
              render={(props) => (
                <InboxMessageTemplate
                  {...props}
                />
              )}
            />
            <Route
              path="/inbox-message-template-recipient-id"
              exact
              render={(props) => (
                <InboxMessageTemplate
                  {...props}
                />
              )}
            />
            <Route
              path="/push-message-settings"
              exact
              render={(props) => (
                <PushMessageSettings
                  {...props}
                />
              )}
            />
            <Route
              path="/auth"
              exact
              render={(props) => (
                <FacebookAuth
                  {...props}
                />
              )}
            />
            <Route
              path="/inbox-products-extension"
              exact
              render={(props) => (
                <InboxProdutsExtension
                  {...props}
                />
              )}
            />
            <Route
              path="/inbox-products-message-extension"
              exact
              render={(props) => (
                <InboxProdutsMessageExtension
                  {...props}
                />
              )}
            />
            <Route
              path="/create-group"
              exact
              render={(props) => (
                <CreateGroup
                  {...props}
                />
              )}
            />
            <Route
              path="/update-group"
              exact
              render={(props) => (
                <UpdateGroup
                  {...props}
                />
              )}
            />
            <Route
              path="/ads-picker"
              exact
              render={(props) => (
                <MetaAdsTemplate
                  {...props}
                />
              )}
            />
            <Route
              path="/ads"
              exact
              render={(props) => (
                <MetaAdsPermission
                  {...props}
                />
              )}
            />
          </div>
        </BrowserRouter>
      </ThemeProvider>
    )
  }
}

export default App
