/** @jsxImportSource theme-ui */

import React, { useState, Fragment } from "react"
import Select from "react-select"
import _ from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getCountries, getCountryCallingCode } from "react-phone-number-input/input"
import countryNames from "react-phone-number-input/locale/en.json"
import getUnicodeFlagIcon from "country-flag-icons/unicode"

import { randomString } from "../../util"
import {
  Container,
  Label,
  StyledTooltip,
  Error,
} from "./CountryCodeSelectFieldStyles"

const renderFlag = (country) => {
  const flag = getUnicodeFlagIcon(country)
  return flag
}

const generalStyles = {
  menu: (styles) => ({
    ...styles,
    width: "180%",
    color: "#2e2e2e",
    zIndex: 999,
  }),
  option: (styles) => ({
    ...styles,
    whitespace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "0.8rem",
    ":hover": {
      ...styles[":hover"],
      overflow: "visible",
    },
  }),
  control: (styles) => ({
    ...styles,
    borderColor: "hsl(0, 0%, 80%)",
    fontSize: "0.8rem",
  }),
}

const errorStyles = {
  ...generalStyles,
  control: (styles) => ({
    ...styles,
    borderColor: "hsl(8, 74%, 49%)",
    boxShadow: "hsl(8, 74%, 49%)",
    "&:hover": {
      borderColor: "hsl(8, 74%, 49%)",
    },
  }),
}

const CountryCodeSelectField = ({
  label,
  tooltipText,
  tooltipOptions,
  className,
  options,
  offsetLabel = false,
  formatCreateLabel,
  placeholderText,
  codeOnly,
  showCountryNameWithFlag,
  error,
  value,
  onChange,
  disabled,
  onFocus,
  onBlur,
  focused,
  hasError,
  ...props
}) => {
  const [randomId] = useState(randomString(10))
  const [focusedThis, setFocusedThis] = useState(false)

  const hasLongError = error?.length >= 40 && hasError
  const hasLabel = !!label

  // const countriesList = getCountries().map((country) => ({
  //   label: `${renderFlag(country)} ${countryNames[country]} +${getCountryCallingCode(country)}`,
  //   value: country,
  //   countryCode: getCountryCallingCode(country)
  // }))

  const getCountriesList = () => {
    if (showCountryNameWithFlag) {
      return getCountries().map((country) => ({
        label: `${renderFlag(country)} ${countryNames[country]}`,
        value: country,
        countryCode: getCountryCallingCode(country),
      }))
    }

    return getCountries().map((country) => ({
      label: `${renderFlag(country)} ${countryNames[country]} +${getCountryCallingCode(country)}`,
      value: country,
      countryCode: getCountryCallingCode(country),
    }))
  }

  const displayValue = (v) => {
    if (!_.isEmpty(v) && !_.isNil(v)) {
      if (codeOnly) {
        return ({
          label: `+${getCountryCallingCode(v.value)}`,
          value: v.value,
          countryCode: getCountryCallingCode(v?.value),
        })
      }
      if (showCountryNameWithFlag) {
        return ({
          label: `${renderFlag(v.value)} ${countryNames[v.value]}`,
          value: v.value,
          countryCode: getCountryCallingCode(v?.value),
        })
      }
      return ({
        label: `${renderFlag(v.value)} +${getCountryCallingCode(v.value)}`,
        value: v.value,
        countryCode: getCountryCallingCode(v?.value),
      })
    }
    return null
  }

  let styles
  if (hasError) {
    styles = props?.errorStyles || errorStyles
  } else {
    styles = props?.generalStyles || generalStyles
  }
  return (
    <Container
      className={className}
      focused={focused || focusedThis}
      disabled={disabled}
      hasError={hasError}
      hasLongError={hasLongError}
      offsetLabel={offsetLabel}
    >
      {label
        ? (
          <Label
            className="label"
            focused={focused || focusedThis}
            hasError={hasError}
          >
            <span>{label}</span>
            {tooltipText
              ? (
                <>
                  <FontAwesomeIcon
                    icon="question-circle"
                    className="tooltip-icon"
                    data-tooltip-html={tooltipText}
                    data-tooltip-id={randomId}
                  />
                  <StyledTooltip
                    id={randomId}
                    {...tooltipOptions}
                  />
                </>
              )
              : null}
            {hasLongError && hasError
              ? (
                <>
                  <FontAwesomeIcon
                    icon="exclamation-triangle"
                    className="tooltip-icon select-field-tooltip-icon"
                    data-tooltip-html={error}
                    data-tooltip-id={`${randomId}-error`}
                  />
                  <StyledTooltip
                    id={`${randomId}-error`}
                    variant="error"
                    {...tooltipOptions}
                  />
                </>
              )
              : null}
          </Label>
        )
        : null}

      <div className="content">
        <Select
          menuPlacement="auto"
          value={displayValue(value)}
          styles={props?.styles || styles}
          isDisabled={disabled}
          placeholder={placeholderText || "Country Code"}
          onChange={(selected) => {
            onChange(selected)
          }}
          onFocus={() => {
            if (onFocus) {
              onFocus()
            }
            setFocusedThis(true)
          }}
          onBlur={() => {
            if (onBlur) {
              onBlur()
            }
            setFocusedThis(false)
          }}
          options={getCountriesList()}
        />
      </div>
      {hasError && !hasLongError
        ? (
          <Error
            className="error-container"
            hasLabel={hasLabel}
          >
            {error}
          </Error>
        )
        : null}
    </Container>
  )
}

export default CountryCodeSelectField
