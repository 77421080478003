import PropTypes from "prop-types"
import React from "react"
import _ from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { setLightness } from "polished"
import { faCircleNotch, faEllipsisH } from "@fortawesome/free-solid-svg-icons"

const propTypes = {
  primary: PropTypes.bool,
  danger: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  borderless: PropTypes.bool,
  inverted: PropTypes.bool,
  text: PropTypes.string,
  icon: PropTypes.string,
  rightIcon: PropTypes.string,
  iconOptions: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
}

const defaultProps = {
  primary: false,
  danger: false,
  disabled: false,
  loading: false,
  borderless: false,
  inverted: false,
  text: "",
  icon: "",
  iconOptions: {},
  className: "",
  rightIcon: "",
  onClick: () => {},
}

const LoadingIconWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.showLoading ? 1 : 0)};
  transition: opacity 0.2s linear;

  .loading-icon {
    color: ${(props) => {
    if (props.primary && !props.inverted) {
      return "#ffffff"
    } if (props.inverted) {
      return props.theme.primary
    }
    return props.theme.gray10
  }};
  }
`
const Button = styled.button`
  border: 1px solid ${(props) => props.theme.gray2};
  border-radius: 4px;
  box-sizing: border-box;
  padding: ${(props) => (props.text ? "6px 10px" : "0")};
  min-width: 34px;
  min-height: 34px;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: ${(props) => (props.alignStart ? "flex-start" : "center")};
  transition: all 100ms linear;
  cursor: pointer;
  background-color: #ffffff;
  position: relative;

  .text {
    color: ${(props) => props.theme.gray7};
    font-size: ${(props) => props.theme.textSm};
    opacity: ${(props) => (props.showLoading ? 0 : 1)};
    transition: opacity 0.2s linear;
  }
  .icon {
    color: ${(props) => props.theme.gray6};
    margin-right: ${(props) => (props.text ? "5px" : "0")};
    font-size: ${(props) => (props.text ? "0.7em" : props.theme.textSm)};
    opacity: ${(props) => (props.showLoading ? 0 : 1)};
    transition: opacity 0.2s linear;

    &.right {
      margin-right: 0;
      margin-left: ${(props) => (props.text ? "5px" : "0")};
    }
  }

  &:hover {
    background: ${(props) => props.theme.gray0};
    border-color: ${(props) => props.theme.gray1};
  }

  &:active {
    background: ${(props) => props.theme.gray2};
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,0.25);
  }

  ${(props) => {
    if (props.disabled) {
      if (props.inverted && props.primary && props.isLoading) {
        return css`
          background: #ffffff;
          color: ${props.theme.primary};

          &:hover {
            background: #ffffff;
            color: ${props.theme.primary};
          }

          .loading-icon {
            color: ${props.theme.primary};
          }
        `
      } if (props.primary && props.isLoading) {
        return css`
          background: ${props.theme.primary};
          color: #ffffff;

          &:hover {
            background: ${props.theme.primary};
            color: #ffffff;
          }
        `
      } if (props.borderless) {
        return css`
          border: none;
          background: transparent;
          cursor: auto;
          .text {
            color: ${props.theme.gray3};
          }
          .icon {
            color: ${props.theme.gray3};
          }

          &:hover {
            background: transparent;
            .text {
              color: ${props.theme.gray3};
            }
            .icon {
              color: ${props.theme.gray3};
            }
          }

          &:active {
            background: transparent;
            .text {
              color: ${props.theme.gray3};
            }
            .icon {
              color: ${props.theme.gray3};
            }
          }

          &:focus {
          outline: 0;
          box-shadow: none;
        }
        `
      }

      return css`
        background: ${props.theme.white};
        border-color: ${props.theme.gray2};
        cursor: auto;

        .text {
          color: ${props.theme.gray2};
        }
        .icon {
          color: ${props.theme.gray2};
        }

        &:hover {
          background: ${props.theme.white};
          border-color: ${props.theme.gray2};
        }

        &:active {
          background: ${props.theme.white};
          border-color: ${props.theme.gray2};
        }
      `
    }
    if (props.danger) {
      if (props.borderless) {
        return css`
          border: none;
          background: transparent;
          cursor: pointer;
          .text {
            color: ${props.theme.error};
          }
          .icon {
            color: ${props.theme.error};
          }
          

          &:hover {
            background: transparent;
            .text {
              color: ${setLightness(0.39, props.theme.error)};
            }
            .icon {
              color: ${setLightness(0.39, props.theme.error)};
            }
          }

          &:active {
            background: transparent;
            .text {
              color: ${setLightness(0.39, props.theme.error)};
            }
            .icon {
              color: ${setLightness(0.39, props.theme.error)};
            }
          }

          &:focus {
            outline: 0;
            box-shadow: none;
          }
        `
      }
      if (props.inverted) {
        return css`
          background: ${props.theme.white};
          border-color: ${props.theme.gray2};
          .text {
            color: ${props.theme.error};
          }
          .icon {
            color: ${props.theme.error};
          }

          &:hover {
            background: ${props.theme.gray0};
            border-color: ${props.theme.gray2};
          }

          &:active {
            background: ${props.theme.gray1};
            border-color: ${props.theme.gray2};
          }
        `
      }
      return css`
        background: ${props.theme.error};
        border-color: ${setLightness(0.39, props.theme.error)};
        .text {
          color: ${props.theme.white};
        }
        .icon {
          color: ${props.theme.white};
        }

        &:hover {
          background: ${setLightness(0.44, props.theme.error)};
          border-color: ${setLightness(0.39, props.theme.error)};
        }

        &:active {
          background: ${setLightness(0.39, props.theme.error)};
          border-color: ${setLightness(0.39, props.theme.error)};
        }
      `
    }
    if (props.primary) {
      if (props.borderless) {
        return css`
          border: none;
          background: transparent;
          cursor: pointer;
          .text {
            color: ${props.theme.primary};
          }
          .icon {
            color: ${props.theme.primary};
          }

          &:hover {
            background: transparent;
            .text {
              color: ${setLightness(0.37, props.theme.primary)};
            }
            .icon {
              color: ${setLightness(0.37, props.theme.primary)};
            }
          }

          &:active {
            background: transparent;
            .text {
              color: ${setLightness(0.27, props.theme.primary)};
            }
            .icon {
              color: ${setLightness(0.27, props.theme.primary)};
            }
          }

          &:focus {
            outline: 0;
            box-shadow: none;
          }

          .loading-icon {
            color: ${props.theme.primary};
          }
        `
      }
      if (props.inverted) {
        return css`
          background: ${props.theme.white};
          border-color: ${props.theme.gray2};
          .text {
            color: ${props.theme.primary};
          }
          .icon {
            color: ${props.theme.primary};
          }

          &:hover {
            background: ${props.theme.gray0};
            border-color: ${props.theme.gray2};
          }

          &:active {
            background: ${props.theme.gray1};
            border-color: ${props.theme.gray2};
          }
        `
      }

      return css`
        background: ${props.theme.primary};
        /* background: ${props.theme.facebookThemeColor}; */
        border-color: ${setLightness(0.57, props.theme.primary)};
        .text {
          color: ${props.theme.white};
        }
        .icon {
          color: ${props.theme.white};
        }

        &:hover {
          background: ${setLightness(0.37, props.theme.primary)};
          border-color: ${setLightness(0.47, props.theme.primary)};
        }

        &:active {
          background: ${setLightness(0.27, props.theme.primary)};
          border-color: ${setLightness(0.47, props.theme.primary)};
        }
      `
    }
    if (props.success) {
      if (props.borderless) {
        return css`
          border: none;
          background: transparent;
          cursor: pointer;
          .text {
            color: ${props.theme.success};
          }
          .icon {
            color: ${props.theme.success};
          }

          &:hover {
            background: transparent;
            .text {
              color: ${setLightness(0.37, props.theme.primary)};
            }
            .icon {
              color: ${setLightness(0.37, props.theme.primary)};
            }
          }

          &:active {
            background: transparent;
            .text {
              color: ${setLightness(0.27, props.theme.primary)};
            }
            .icon {
              color: ${setLightness(0.27, props.theme.primary)};
            }
          }
        `
      }
      if (props.inverted) {
        return css`
          background: ${props.theme.white};
          border-color: ${props.theme.gray2};
          .text {
            color: ${props.theme.success};
          }
          .icon {
            color: ${props.theme.success};
          }

          &:hover {
            background: ${props.theme.gray0};
            border-color: ${props.theme.gray2};
          }

          &:active {
            background: ${props.theme.gray1};
            border-color: ${props.theme.gray2};
          }
        `
      }
      return css`
        background: ${props.theme.success};
        border-color: ${setLightness(0.57, props.theme.success)};
        .text {
          color: ${props.theme.white};
        }
        .icon {
          color: ${props.theme.white};
        }

        &:hover {
          background: ${setLightness(0.37, props.theme.success)};
          border-color: ${setLightness(0.47, props.theme.success)};
        }

        &:active {
          background: ${setLightness(0.27, props.theme.success)};
          border-color: ${setLightness(0.47, props.theme.success)};
        }
      `
    }
    if (props.borderless) {
      return css`
        border: none;
        background: transparent;
        cursor: pointer;

        .text {
          color: ${props.primary ? props.theme.primary : props.theme.gray4};
        }
        .icon {
          color: ${props.primary ? props.theme.primary : props.theme.gray4};

        }

        &:hover {
          background: transparent;
          .text {
            color: ${props.primary ? setLightness(0.37, props.theme.primary) : setLightness(0.47, props.theme.gray4)};
          }
          .icon {
            color: ${props.primary ? setLightness(0.37, props.theme.primary) : setLightness(0.47, props.theme.gray4)};

          }
        }

        &:active {
          background: transparent;
          .text {
            color: ${props.primary ? setLightness(0.27, props.theme.primary) : setLightness(0.27, props.theme.gray4)};

          }
          .icon {
            color: ${props.primary ? setLightness(0.27, props.theme.primary) : setLightness(0.27, props.theme.gray4)};

          }
        }

        &:focus {
          outline: 0;
          box-shadow: none;
        }
      `
    }
  }}
`

class BoxButton extends React.Component {
  render() {
    const {
      text,
      dropDownIcon,
      className = "",
      loading,
      disabled,
      primary,
      inverted,
      icon,
    } = this.props

    const defaultClassName = `box-button-container ${className}`
    return (
      <Button
        {..._.omit(this.props, ["loading"])}
        isLoading={this.props.loading}
        onClick={(e) => {
          if (!disabled && !loading) this.props.onClick(e)
        }}
        showLoading={loading}
        className={defaultClassName}
        data-cy={this.props["data-cy"] || text}
      >
        {dropDownIcon
          ? <FontAwesomeIcon className="icon" icon={faEllipsisH} fixedWidth />
          : null}
        {icon && <FontAwesomeIcon icon={icon} className="icon" /> }
        <span
          className="text"
        >
          {text}
        </span>
        <LoadingIconWrapper
          primary={primary}
          inverted={inverted}
          showLoading={loading}
        >
          <FontAwesomeIcon
            className="loading-icon"
            icon={faCircleNotch}
            spin
            fixedWidth
          />
        </LoadingIconWrapper>
      </Button>
    )
  }
}

BoxButton.propTypes = propTypes
BoxButton.defaultProps = defaultProps

export default BoxButton
