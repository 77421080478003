import React from "react"
import styled from "@emotion/styled"
import { ErrorBoundary } from "react-error-boundary"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Tooltip } from "react-tooltip"

const TOOLTIP_BG_COLOR = "#222"
const TOOLTIP_FG_COLOR = "#fff"
const ERROR_BG_COLOR = "#f8d7da"
const ERROR_TEXT_COLOR = "#721c24"
const ERROR_BORDER_COLOR = "#f5c6cb"

const StyledTooltip = styled(Tooltip)`
  max-width: 400px;
  overflow-wrap: break-word;
  z-index: 9999;
  a {
    color: inherit !important;
  }
  .multi-line {
    text-align: left !important;
  }
  &.react-tooltip {
    background: ${(p) => (!p.variant ? (p.backgroundColor ?? TOOLTIP_BG_COLOR) : null)};
    color: ${(p) => (!p.variant ? (p.textColor ?? TOOLTIP_FG_COLOR) : null)};
    span {
      color: ${(p) => (!p.variant ? (p.textColor ?? TOOLTIP_FG_COLOR) : null)};
    }
  }
`

const ErrorContainer = styled.div`
  background-color: ${ERROR_BG_COLOR};
  color: ${ERROR_TEXT_COLOR};
  border: 1px solid ${ERROR_BORDER_COLOR};
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
  max-width: 300px;
`

const ErrorIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
  font-size: 16px;
`

const ErrorText = styled.span`
  flex: 1;
`

function ErrorMessage() {
  return (
    <ErrorContainer>
      <ErrorIcon icon="exclamation-triangle" />
      <ErrorText>Oops! There was an issue rendering this tooltip. Please try again later.</ErrorText>
    </ErrorContainer>
  )
}

function ReactTooltip(props) {
  const modProps = {
    ...props,
  }
  if (modProps.withoutArrow) {
    modProps.noArrow = true
    delete modProps.withoutArrow
  }
  return (
    <ErrorBoundary
      fallback={ErrorMessage}
    >
      <StyledTooltip {...modProps} />
    </ErrorBoundary>
  )
}

export {
  ReactTooltip,
}
