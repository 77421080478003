import styled from "@emotion/styled"
import { css } from "@emotion/react"

export const StyledInput = styled.input`
  font-size: ${(props) => (props.masked ? "48px" : "16px")};
  color: ${(props) => (props.masked ? props.theme.gray6 : props.theme.gray7)};
`

export const CharacterCounter = styled.div`
  font-size: ${(props) => props.theme.textSm};
  color: ${(props) => {
    if (props.hasError) {
      return props.theme.dangerText
    } if (props.focused) {
      return props.theme.themeColor
    }
    return props.theme.gray5
  }};
`

export const LabelContainer = styled.div`
  display: flex;
  font-size: 0.8rem;
  margin-bottom: ${(p) => (p.noMarginBottom ? "0px" : "2px")};
  justify-content: ${(props) => {
    if (props.labelOnly) {
      return "flex-start"
    } if (props.characterCountOnly) {
      return "flex-end"
    }
    return "space-between"
  }};

  color: ${(props) => {
    if (props.hasError) {
      return props.theme.dangerText
    } if (props.focused) {
      return props.theme.themeColor
    }
    return props.theme.gray7
  }};
`

export const Content = styled.div`
  background: ${(props) => {
    if (props.disabled && props.borderStyle) {
      return "#f2f2f2"
    } if (props.borderStyle) {
      return "#ffffff"
    }
    return "transparent"
  }};
  margin-left: ${(props) => (props.offsetLabel ? "12px" : "0")};
  display: flex;
  align-items: center;
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  border-bottom: 2px solid ${(props) => {
    if (!props.borderStyle) {
      if (props.hasError) {
        return props.theme.dangerText
      }
      if (props.focused) {
        return props.theme.themeColor
      }
      return props.theme.borderColorDark
    }
  }};
  padding: ${(props) => props.borderStyle && "4px"};
  min-height: ${(props) => {
    if (props.borderStyle === "L") {
      return "48px"
    } if (props.borderStyle === "M") {
      return "38px"
    } if (props.borderStyle === "S") {
      return "28px"
    }
  }};
  border-radius: ${(props) => (props.borderStyle ? "4px" : "0px")};
  box-sizing: border-box;
  font-size: ${(props) => {
    if (props.borderStyle === "L") {
      return "1.3em"
    } if (props.borderStyle === "M") {
      return "1em"
    } if (props.borderStyle === "S") {
      return "0.9em"
    }
  }};
  border: ${(props) => {
    if (props.borderStyle) {
      if (props.hasError) {
        return `1px solid ${props.theme.dangerText}`
      }
      if (props.focused && !props.disabled) {
        return `1px solid ${props.theme.themeColor}`
      }
      return `1px solid ${props.theme.borderColorDark}`
    }
  }};
  padding: ${(props) => props.borderStyle && "4px"};

  ${(props) => {
    if (props.noBorderStyle) {
      return css`
        border: none;
        min-height: auto;
      `
    }
  }}

  textarea {
    border: none;
    width: 100%;
    resize: none;
    font-size: 1em;
    background: transparent;
    color: ${(props) => {
    if (props.disabled) {
      return "#686666"
    } if (props.placeholder) {
      return props.theme.placeholderText
    }
    return props.theme.gray7
  }};
    outline: none;
    height: 16px;

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: ${(props) => props.theme.placeholderText};
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: ${(props) => props.theme.placeholderText};
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: ${(props) => props.theme.placeholderText};
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: ${(props) => props.theme.placeholderText};
    }
  }

  input {
    border: none;
    width: 100%;
    font-size: 0.9rem;
    resize: none;
    background: transparent;
    color: ${(props) => props.readOnly && props.theme.gray7};

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: ${(props) => props.theme.placeholderText};
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: ${(props) => props.theme.placeholderText};
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: ${(props) => props.theme.placeholderText};
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: ${(props) => props.theme.placeholderText};
    }
  }

  input:active, input:focus {
    outline: none;
  }
`

export const ErrorContainer = styled.div`
  font-size: 0.7rem;
  color: ${(props) => props.theme.dangerText};
  margin-top: 2px;
`
