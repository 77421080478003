import React, { useEffect, useState } from "react"
import qs from "query-string"
import _ from "lodash"
import styled from "styled-components"
import { isPossiblePhoneNumber } from "react-phone-number-input"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faAdd, faTimes, faTrash,
  faTriangleExclamation,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons"
import { useUpdateInboxHeader } from "../../hooks/useUpdateInboxHeader"
import theme from "../../stylesheets/theme"
import { ModalBody } from "../../components/Radiate/ThemedModal/ThemedModal"
import NewBoxButton from "../../components/Radiate/NewBoxButton/NewBoxButton"
import CountryCodeSelectField from "../../components/Radiate/CountryCodeSelectField/CountryCodeSelectField"
import { PhoneFieldGroup, CountryCodeWrapper, PhoneWrapper } from "../CreateGroup/CreateGroup"
import TextField from "../../components/Radiate/TextField/TextField"
import {
  UserPill,
  ParticipantsContainer,
  ParticipantsCount,
  NoParticipantsMessage,
} from "./GroupSharedStyles"
import { useMutate } from "../../hooks/useMutate"
import { closeInboxModal, sendToastMessageToInbox } from "../../components/util"

const SERVER_API_PATH = process.env.REACT_APP_SERVER_API_PATH
const INBOX_URL = process.env.REACT_APP_INBOX_URL

const SPACING = "8px"
const LARGE_SPACING = "16px"

const FONT_SIZE_TITLE = "14px"

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: ${SPACING};
  background-color: ${theme.gray02};
`

const GroupMembersTitle = styled.div`
  font-weight: bold;
  font-size: ${FONT_SIZE_TITLE};
`

export const ActionBar = styled.div`
  box-sizing: border-box;
  border-top: 1px solid ${theme.gray1};
  padding: 8px 12px;
  display: flex;
  justify-content: ${(p) => (p.hasErrors ? "space-between" : "flex-end")};
  align-items: center;
  justify-self: flex-end;

  .box-button-container {
    margin-left: 8px;
  }
`

const MaxMembersMessage = styled.div`
  color: ${theme.gray5};
  font-size: ${FONT_SIZE_TITLE};
  margin-top: ${SPACING};
  text-align: center;
  font-style: italic;
`

const ErrorMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 0, 0, 0.1);
  padding: ${SPACING};
  border-radius: 4px;
  font-size: ${FONT_SIZE_TITLE};
  color: ${theme.dangerText};
  margin-bottom: ${SPACING};
  word-break: break-word;
  gap: ${SPACING};
`

const NoteContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.gray1};
  padding: ${SPACING};
  border-radius: 4px;
  font-size: ${FONT_SIZE_TITLE};
  color: ${theme.gray6};
  margin-bottom: ${SPACING};
  gap: ${SPACING};
`

const CreateGroup = ({ location }) => {
  const query = qs.parse(location.search)
  const participants = query.participants ? query.participants?.split(",")?.map((p) => `+${p}`) : []
  const groupId = query.groupId
  const channelId = query.channel
  const [removedParticipants, setRemovedParticipants] = useState([])
  const [signedContext, setSignedContext] = useState(null)
  const [payload, setPayload] = useState(null)
  const [addedPhoneNumbers, setAddedPhoneNumbers] = useState([])
  const [phoneErrors, setPhoneErrors] = useState({})

  const deleteGroup = useMutate({
    url: `${SERVER_API_PATH}/group`,
    payload,
    signedContext,
    method: "DELETE",
    onSuccess: () => {
      sendToastMessageToInbox({
        message: "Group deleted successfully",
        type: "success",
        options: {
          duration: 15000,
        },
      })
      closeInboxModal()
    },
  })

  const updateGroup = useMutate({
    url: `${SERVER_API_PATH}/group`,
    payload,
    signedContext,
    method: "PATCH",
    onSuccess: () => {
      sendToastMessageToInbox({
        message: "Group updated successfully",
        type: "success",
        options: {
          duration: 15000,
        },
      })
      closeInboxModal()
    },
  })

  const receiveMessage = (event) => {
    const whitelistedOrigins = INBOX_URL.split("||")
    if (!whitelistedOrigins.includes(event.origin)) {
      return
    }
    if (event?.data) {
      if (!_.isEmpty(event.data?.payload)) {
        setPayload(event.data?.payload)
        setSignedContext(event.data?.signedContext)
      }
    }
  }

  useEffect(() => {
    if (navigator.userAgent !== "ReactSnap") {
      window.addEventListener("message", receiveMessage, false)
      window.parent.postMessage("iframeFinishLoading", "*")
    }
    return () => {
      window.removeEventListener("message", receiveMessage, false)
    }
  }, [])

  useUpdateInboxHeader({ title: "Update WhatsApp Group", isGroupsView: true })

  const validatePhoneNumber = (number, countryCode) => {
    if (!number) return { text: "Please enter a phone number" }
    if (!countryCode) return { text: "Please select a country code for this number" }
    const fullNumber = `+${countryCode}${number}`
    if (!isPossiblePhoneNumber(fullNumber)) {
      return { text: "The phone number you've entered appears to be invalid" }
    }
    return null
  }

  const handlePhoneNumberDelete = (phoneNumber) => {
    setRemovedParticipants((prev) => [...prev, { user: phoneNumber }])
  }

  const handleRemovedParticipantDelete = (user) => {
    setRemovedParticipants((prev) => prev.filter((participant) => participant.user !== user))
  }

  const handleEditGroup = () => {
    const addParticipants = addedPhoneNumbers
      .filter((p) => p.number && p.countryCode?.countryCode)
      .map((p) => ({ user: `+${p.countryCode?.countryCode}${p.number}` }))

    const removeParticipants = removedParticipants.filter((p) => p.user)

    if (addParticipants.length) {
      const newErrors = {}
      let hasErrors = false

      addedPhoneNumbers.forEach((phone, index) => {
        const error = validatePhoneNumber(phone.number, phone.countryCode?.countryCode)
        if (error) {
          newErrors[index] = error
          hasErrors = true
        }
      })

      setPhoneErrors(newErrors)
      if (!hasErrors) {
        updateGroup.mutate({
          addParticipants,
          groupId,
          channelId,
        })
      }
    } else if (removedParticipants.length) {
      updateGroup.mutate({
        removeParticipants,
        groupId,
        channelId,
      })
    }
  }

  const handleDeleteGroup = () => {
    // eslint-disable-next-line no-restricted-globals
    const c = confirm("Are you sure you want to delete this group with all participants?")

    if (c) {
      deleteGroup.mutate({
        groupId,
        channelId,
      })
    }
  }

  const addPhoneNumber = () => {
    setAddedPhoneNumbers([...addedPhoneNumbers, { countryCode: null, number: "" }])
  }

  const currentParticipants = participants.filter(
    (phoneNumber) => !removedParticipants.some((removed) => removed.user === phoneNumber),
  )

  const completeAddedPhoneNumbers = addedPhoneNumbers
    .filter(({ countryCode, number }) => countryCode?.countryCode && number)

  const addedParticipants = completeAddedPhoneNumbers
    .map(({ countryCode, number }) => ({
      user: `+${countryCode?.countryCode}${number}`,
    }))

  const handleCountryCodeChange = (index, countryCode) => {
    const updatedNumbers = [...addedPhoneNumbers]
    updatedNumbers[index] = { ...updatedNumbers[index], countryCode }
    setAddedPhoneNumbers(updatedNumbers)
  }

  const handlePhoneNumberChange = (index, phoneNumber) => {
    const numericValue = phoneNumber.replace(/[^0-9]/g, "")
    const updatedNumbers = [...addedPhoneNumbers]
    updatedNumbers[index] = { ...updatedNumbers[index], number: numericValue }
    setAddedPhoneNumbers(updatedNumbers)
    setPhoneErrors((prev) => ({
      ...prev,
      [index]: null,
    }))
  }

  const handleAddedParticipantDelete = (index) => {
    // eslint-disable-next-line no-shadow
    setAddedPhoneNumbers((prev) => prev.filter((_, i) => i !== index))
  }

  const totalParticipants = currentParticipants.length + addedParticipants.length

  return (
    <FormContainer>
      <ModalBody style={{
        display: "flex",
        flexDirection: "column",
        gap: LARGE_SPACING,
        padding: LARGE_SPACING,
      }}
      >
        <NoteContainer>
          <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: "8px" }} />
          You can either add or remove participants at one time
        </NoteContainer>
        {deleteGroup?.error && (
          <ErrorMessageContainer>
            <FontAwesomeIcon icon={faTriangleExclamation} style={{ marginRight: "8px" }} />
            {deleteGroup?.error}
          </ErrorMessageContainer>
        )}
        {updateGroup?.error && (
          <ErrorMessageContainer>
            <FontAwesomeIcon icon={faTriangleExclamation} style={{ marginRight: "8px" }} />
            {updateGroup?.error}
          </ErrorMessageContainer>
        )}
        <div style={{ display: "flex", flexDirection: "column", gap: LARGE_SPACING }}>
          <ParticipantsContainer>
            <ParticipantsCount>
              {totalParticipants}
              /8
            </ParticipantsCount>
            <div style={{ display: "flex", flexWrap: "wrap", gap: SPACING }}>
              {(totalParticipants) === 0 ? (
                <NoParticipantsMessage>No participants.</NoParticipantsMessage>
              ) : (
                currentParticipants.map((phoneNumber) => (
                  <UserPill
                    key={phoneNumber}
                    phoneNumber={phoneNumber}
                    onPhoneNumberDelete={() => handlePhoneNumberDelete(phoneNumber)}
                    disableDelete={addedPhoneNumbers.length > 0}
                  />
                ))
              )}
              {
                addedParticipants.map(({ user }, index) => (
                  <UserPill key={user} phoneNumber={user} onPhoneNumberDelete={() => handleAddedParticipantDelete(index)} isAdded />
                ))
              }
            </div>
            {currentParticipants.length + addedParticipants.length >= 9 && (
              <MaxMembersMessage>
                You cannot add more members because you have reached the maximum number allowed.
              </MaxMembersMessage>
            )}
          </ParticipantsContainer>
          {(removedParticipants.length > 0) && (
          <>
            <GroupMembersTitle>Removed participants</GroupMembersTitle>
            <div style={{
              display: "flex", flexWrap: "wrap", justifyContent: "flex-start", gap: SPACING,
            }}
            >
              {
                removedParticipants.map(({ user }) => (
                  <UserPill key={user} phoneNumber={user} onPhoneNumberDelete={() => handleRemovedParticipantDelete(user)} isRemoved>
                    <FontAwesomeIcon
                      icon={faTimes}
                      style={{ color: theme.dangerText, cursor: "pointer" }}
                      onClick={() => handleRemovedParticipantDelete(user)}
                    />
                  </UserPill>
                ))
              }
            </div>
          </>
          )}
          {(addedPhoneNumbers.length > 0) && (
            <>
              <GroupMembersTitle>Add participants</GroupMembersTitle>
              {addedPhoneNumbers.map((phone, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <PhoneFieldGroup key={index}>
                  <CountryCodeWrapper>
                    <CountryCodeSelectField
                      value={phone.countryCode}
                      onChange={(countryCode) => handleCountryCodeChange(index, countryCode)}
                      codeOnly
                      generalStyles={{
                        menu: (styles) => ({
                          ...styles,
                          width: "180%",
                          color: "#2e2e2e",
                          zIndex: 999,
                        }),
                        menuList: (styles) => ({
                          ...styles,
                          maxHeight: "200px",
                        }),
                        option: (styles) => ({
                          ...styles,
                          whitespace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          ":hover": {
                            ...styles[":hover"],
                            overflow: "visible",
                          },
                          fontSize: "0.8rem",
                        }),
                        control: (styles) => ({
                          ...styles,
                          borderColor: "hsl(0, 0%, 80%)",
                          fontSize: "0.8rem",
                        }),
                      }}
                    />
                  </CountryCodeWrapper>
                  <PhoneWrapper>
                    <TextField
                      placeholder="Phone number"
                      useInput
                      value={phone.number}
                      error={phoneErrors[index]}
                      onChange={(e) => handlePhoneNumberChange(index, e.target.value)}
                    />
                  </PhoneWrapper>
                </PhoneFieldGroup>
              ))}
            </>
          )}
          {(removedParticipants.length === 0) && (
            <div style={{ display: "flex", justifyContent: "flex-end", gap: SPACING }}>
              <NewBoxButton
                text="Add Another Participant"
                icon={faAdd}
                onClick={addPhoneNumber}
                disabled={totalParticipants >= 8 || removedParticipants.length > 0}
              />
            </div>
          )}
        </div>
      </ModalBody>
      <ActionBar>
        <NewBoxButton
          danger
          icon={faTrash}
          style={{
            position: "relative",
            top: "-3px",
          }}
          text="Delete Group"
          primary
          loading={deleteGroup.loading}
          disabled={updateGroup.loading}
          onClick={handleDeleteGroup}
        />
        <NewBoxButton
          style={{
            position: "relative",
            top: "-3px",
          }}
          text="Edit Participants"
          primary
          disabled={deleteGroup.loading || (completeAddedPhoneNumbers.length === 0 && removedParticipants.length === 0)}
          loading={updateGroup.loading}
          onClick={handleEditGroup}
        />
      </ActionBar>
    </FormContainer>
  )
}
export default CreateGroup
