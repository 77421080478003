import styled from "@emotion/styled"
import { ReactTooltip } from "../Tooltip/Tooltip"

export const StyledTooltip = styled(ReactTooltip)`
  &:before, &:after {
    border-width: 0px !important;
  }
`

export const Container = styled.div`
  padding-bottom: ${(props) => {
    if (props.hasError && !props.hasLongError) {
      return "0"
    }
  }};
`

export const Label = styled.div`
  color: ${(props) => {
    if (props.hasError) {
      return props.theme.dangerText
    }
    if (props.focused) {
      return props.theme.themeColor
    }
    return props.theme.gray7
  }};
  font-size: 0.8rem;
  margin-bottom: 2px;

  span {
    margin-right: 4px;
  }

  .tooltip-icon.select-field-tooltip-icon {
    color: ${(props) => {
    if (props.hasError) {
      return props.theme.dangerText
    }
    if (props.focused) {
      return props.theme.themeColor
    }
    return props.theme.darkButtonText
  }};
  }
`

export const Error = styled.div`
  margin-top: 2px;
  color: ${(props) => props.theme.dangerText};
  font-size: ${(props) => props.theme.textSm};
`
