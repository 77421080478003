import { useEffect } from "react"
import _ from "lodash"

function useUpdateInboxHeader({
  title,
  isInboxProductsExtensionView = false,
  isGroupsView = false,
}) {
  const postObject = {
    action: "iframeHeaderUpdate",
    meta: {
      title,
    },
  }

  useEffect(() => {
    if (!_.isString(title)) {
      throw new Error("Title must be a string")
    }
    if (isInboxProductsExtensionView || isGroupsView) {
      if (navigator.userAgent !== "ReactSnap") {
        window.parent.postMessage(postObject, "*")
      }
    }
  }, [])
}

export {
  useUpdateInboxHeader,
}
