/* eslint-disable react/jsx-no-useless-fragment */
import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"

import TextareaAutosize from "react-autosize-textarea"
import variables from "../../../stylesheets/var.scss"

import * as Styles from "./TextFieldStyles"

const propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.object,
  masked: PropTypes.bool,
  borderStyle: PropTypes.string,
  readOnly: PropTypes.bool,
}

const defaultProps = {
  onChange: () => {},
  disabled: false,
  placeholder: "",
  className: "",
  value: "",
  label: "",
  error: {},
  masked: false,
  borderStyle: "M",
  readOnly: false,
}

class TextField extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      focused: false,
    }
  }

  render() {
    const {
      label,
      className,
      disabled,
      error,
      masked,
      borderStyle,
      readOnly,
      maxLength,
      hideLengthCounter,
      useInput,
      maxRows,
      minRows,
      placeholder,
      noBorderStyle = false,
    } = this.props
    let { value } = this.props
    const propsToPassDown = _.omit(this.props, [
      "label",
      "className",
      "value",
      "error",
      "masked",
      "borderStyle",
      "noBorderStyle",
    ])
    if (!value) {
      value = ""
    }
    const hasError = !_.isEmpty(error)
    return (
      <div className={className}>
        <Styles.LabelContainer
          hasError={hasError}
          focused={this.state.focused}
          labelOnly={label.length && !(_.isNumber(maxLength) && !hideLengthCounter)}
          labelAndCharacterCount={label.length && (_.isNumber(maxLength) && !hideLengthCounter)}
          characterCountOnly={!label.length && (_.isNumber(maxLength) && !hideLengthCounter)}
          noMarginBottom={!label.lengh}
        >
          {label.length
            ? <div className="label">{label}</div>
            : null}
          {_.isNumber(maxLength) && !hideLengthCounter
            ? (
              <Styles.CharacterCounter
                hasError={hasError}
                focused={this.state.focused}
              >
                {_.get(value, "length", 0)}
                /
                {maxLength}
              </Styles.CharacterCounter>
            )
            : null}

        </Styles.LabelContainer>
        <Styles.Content
          className="content"
          focused={this.state.focused}
          disabled={disabled}
          hasError={hasError}
          borderStyle={borderStyle}
          readOnly={readOnly}
          placeholder={placeholder}
          noBorderStyle={noBorderStyle}
        >
          {useInput
            ? (
              <>
                {masked
                  ? (
                    <Styles.StyledInput
                      className="masked-input"
                      value="●●●●●●●●"
                      readOnly
                    />
                  )
                  : (
                    <Styles.StyledInput
                      value={value}
                      {...propsToPassDown}
                      onFocus={() => {
                        if (this.props.onFocus) {
                          this.props.onFocus()
                        }
                        this.setState({
                          focused: true,
                        })
                      }}
                      onBlur={() => {
                        if (this.props.onBlur) {
                          this.props.onBlur()
                        }
                        this.setState({
                          focused: false,
                        })
                      }}
                    />
                  )}
              </>
            )
            : (
              <TextareaAutosize
                value={value}
                {...propsToPassDown}
                disabled={disabled || readOnly}
                maxRows={maxRows}
                minRows={minRows}
                style={{ fontFamily: variables.fontPrimary, fontSize: "0.9rem" }}
                onFocus={() => {
                  if (this.props.onFocus) {
                    this.props.onFocus()
                  }
                  this.setState({
                    focused: true,
                  })
                }}
                onBlur={() => {
                  if (this.props.onBlur) {
                    this.props.onBlur()
                  }
                  this.setState({
                    focused: false,
                  })
                }}
              />
            )}
        </Styles.Content>
        {hasError
          ? (
            <Styles.ErrorContainer className="error-container">
              <div className="text">{error.text}</div>
            </Styles.ErrorContainer>
          )
          : null}
      </div>
    )
  }
}

TextField.propTypes = propTypes
TextField.defaultProps = defaultProps

export default TextField
